<template>
  <el-card class="box-card multiple-checkbox" shadow="hover">
    <div slot="header" class="title">
      <div class="field-label">
        <span class="oa-text-danger" v-if="entity.Required">*</span>
        <span>{{ entity.Index + ". " }}</span>
        <el-input v-model="entity.Title" size="mini" placeholder="标题"></el-input>
        <label class="oa-text-grey type-label">（多选）</label>
      </div>
      <div>
        <el-button @click="addOption" type="text" size="mini">
          [添加]
        </el-button>
        <el-button @click="addOtherOption" type="text" size="mini">
          [添加其他]
        </el-button>
        <el-button @click="removeQuestion(entity.Index)" class="oa-text-danger hover" type="text" size="mini">
          [删除]
        </el-button>
        <el-checkbox v-model="entity.Required" class="required-checkbox">必选</el-checkbox>
        <el-button @click="sortQuestion(entity.Index, 'up')" type="text" class="sort-button">
          <font-awesome-icon fas icon="long-arrow-alt-up"></font-awesome-icon>
        </el-button>
        <el-button @click="sortQuestion(entity.Index, 'down')" type="text" class="sort-button">
          <font-awesome-icon fas icon="long-arrow-alt-down"></font-awesome-icon>
        </el-button>
      </div>
    </div>
    <ul class="option-ul">
      <li v-for="(item, index) in entity.Options" :key="item.Id">
        <el-input v-model="item.Title" class="option-input" size="mini" :placeholder="'选项' + (index + 1)"></el-input>
        <el-button @click="removeOption(index)" type="text" class="oa-text-danger hover">
          <font-awesome-icon fas icon="times"></font-awesome-icon>
        </el-button>
        <el-button @click="sortOption(index, 'up')" type="text" class="sort-button">
          <font-awesome-icon fas icon="long-arrow-alt-up"></font-awesome-icon>
        </el-button>
        <el-button @click="sortOption(index, 'down')" type="text" class="sort-button">
          <font-awesome-icon fas icon="long-arrow-alt-down"></font-awesome-icon>
        </el-button>
        <el-checkbox v-model="item.IsDefault" class="required-checkbox">默认选项</el-checkbox>
      </li>
      <li v-show="entity.HasOther">
        <label class="base-info-label">其他：</label>
        <el-checkbox v-model="entity.OtherRequired">必填</el-checkbox>
        <label class="oa-text-grey base-info-label">最小长度</label>
        <el-input v-model="entity.OtherMinTextLength" size="mini" placeholder="最小长度" class="base-info-input"></el-input>
        <label class="oa-text-grey base-info-label" style="margin-left: 10px;">最大长度</label>
        <el-input v-model="entity.OtherMaxTextLength" size="mini" placeholder="最大长度" class="base-info-input"></el-input>
      </li>
    </ul>
    <el-input v-if="inputShow" v-model="otherAnswer" class="option-input" size="mini" placeholder="请输入内容"></el-input>
  </el-card>
</template>

<script>

// 问卷：多选
export default {
  name: 'QuestionnaireMultipleCheckBox',
  props: {
    // 问题
    value: { type: Object, default: () => { return {} } }
  },
  data () {
    return {
      inputShow: false,
      list: [],
      entity: {}, // 实体
      otherAnswer: ''
    }
  },
  watch: {
    value (newValue) {
      this.entity = newValue
    }
  },
  methods: {
    sortQuestion (index, type) {
      this.$emit('sortQuestion', index, type)
    },
    removeQuestion (index) {
      this.$emit('removeQuestion', index)
    },
    addOption () {
      const index = this.entity.Options.length
      this.entity.Options.push({
        Index: (index + 1),
        Title: '',
        IsDefault: false
      })
    },
    addOtherOption () {
      this.entity.HasOther = true
    },
    removeOption (index) {
      this.entity.Options.splice(index, 1)
    },
    sortOption (index, type) {
      if (type === 'up') {
        const preIndex = index - 1
        if (preIndex > -1) {
          const preOption = this.entity.Options[preIndex]
          const thisOption = this.entity.Options[index]
          this.$set(this.entity.Options, index, { ...preOption, Index: (index + 1) })
          this.$set(this.entity.Options, preIndex, { ...thisOption, Index: (preIndex + 1) })
        }
      } else {
        const nextIndex = index + 1
        if (this.entity.Options.length > nextIndex) {
          const thisOption = this.entity.Options[index]
          const nextOption = this.entity.Options[nextIndex]
          this.$set(this.entity.Options, index, { ...nextOption, Index: (index + 1) })
          this.$set(this.entity.Options, nextIndex, { ...thisOption, Index: (nextIndex + 1) })
        }
      }
    }
  },
  created () {
    this.entity = this.value
  }
}
</script>

<style scoped lang="scss">
.multiple-checkbox {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .required {
    margin: 2px 5px 0 2px;
  }

  .type-label {
    font-size: 14px;
  }

  .required-checkbox {
    margin-left: 10px;
  }

  .sort-button:first-child {
    margin-left: 10px;
  }

  .sort-button:last-child {
    margin-left: 0;
  }

  .option-ul {
    li {
      margin-bottom: 10px;
    }
  }

  .option-input {
    margin-right: 10px;
  }

  .base-info-label {
    margin-right: 10px;
    font-size: 12px;
  }
}
</style>
