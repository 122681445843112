<template>
  <div>
    <div class="row">
      <el-button size="mini" @click="addSingle">添加单选</el-button>
      <el-button size="mini" @click="addMultiple">添加多选</el-button>
      <el-button size="mini" @click="addInput">添加填空</el-button>
    </div>
    <div class="question-box">
      <div v-for="item in list" :key="item.Id">
        <single-radio v-if="!item.IsTextField && !item.MultiSelect" :value="item" @sortQuestion="sortQuestion"
          @removeQuestion="removeQuestion"></single-radio>
        <multiple-checkbox v-else-if="item.MultiSelect" :value="item" @sortQuestion="sortQuestion"
          @removeQuestion="removeQuestion">
        </multiple-checkbox>
        <q-input v-else-if="item.IsTextField" :value="item" @sortQuestion="sortQuestion"
          @removeQuestion="removeQuestion">
        </q-input>
      </div>
    </div>
  </div>
</template>

<script>
import MultipleCheckbox from './QuestionnaireMultipleCheckbox'
import SingleRadio from './QuestionnaireSingleRadio'
import QInput from './QuestionnaireInput'

// 组件：问卷
export default {
  name: 'Questionnaire',
  props: {
    value: { type: Array, default: () => { return [] } }
  },
  data () {
    return {
      list: [],
      loading: false
    }
  },
  watch: {
    value (newValue) {
      this.list = newValue
    },
    list (newValue) {
      this.$emit('input', newValue)
    }
  },
  methods: {
    addSingle () {
      const index = this.list.length
      const entity = {
        Index: (index + 1),
        IsTextField: false,
        MultiSelect: false,
        Options: []
      }
      this.list.push(entity)
    },
    addMultiple () {
      const index = this.list.length
      const entity = {
        Index: (index + 1),
        IsTextField: false,
        MultiSelect: true,
        Options: []
      }
      this.list.push(entity)
    },
    addInput () {
      const index = this.list.length
      const entity = {
        Index: (index + 1),
        IsTextField: true,
        MultiSelect: false,
        Options: []
      }
      this.list.push(entity)
    },
    sortQuestion (index, type) {
      if (type === 'up') {
        const preIndex = index - 1
        if (preIndex > -1) {
          const preOption = this.list[preIndex]
          const thisOption = this.list[index]
          this.$set(this.list, index, { ...preOption, Index: index })
          this.$set(this.list, preIndex, { ...thisOption, Index: preIndex })
        }
      } else {
        const nextIndex = index + 1
        if (this.list.length > nextIndex) {
          const thisOption = this.list[index]
          const nextOption = this.list[nextIndex]
          this.$set(this.list, index, { ...nextOption, Index: index })
          this.$set(this.list, nextIndex, { ...thisOption, Index: nextIndex })
        }
      }
    },
    removeQuestion (index) {
      // 复制数组重新排序
      const arr = this.list.concat()
      arr.splice(index, index)
      arr.forEach((e, i) => {
        e.Index = (i + 1)
      })
      this.list = arr
    }
  },
  created () {
    this.list = this.value
  },
  components: {
    MultipleCheckbox,
    SingleRadio,
    QInput
  }
}
</script>

<style lang="scss" scoped>
.question-box {
  margin-top: 10px;
}
</style>
